import { DistrictCodes, Nationalities } from "apollo/data";
import lodash from "lodash";

export const GhanaPostRegex = new RegExp(
  `^(${lodash.map(DistrictCodes, "code").join("|")})-\\d{3,4}-\\d{4}$`,
  "g"
);

export const GhanaCardRegex = new RegExp(
  `^(${lodash
    .map(Nationalities, "alpha_3_code")
    .join("|")})-\\d{9}-(\\d|[A-Z])$`,
  "g"
);

export const IpAddressRegex = new RegExp(
  /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(:\d+)?$/,
  'g'
)

export const MeterShipmentCustomerSerialNumberRegex = new RegExp(
  /^(S|P)?[0-9]*$/,
  'g'
)

export const OnlyDigitsRegex = new RegExp(
  /^[0-9]*$/,
  'g'
)

export const MacAddressRegex = new RegExp(
  /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
  'g'
)

export const PhoneNumberRegex = new RegExp(
  /^0(2|3|5)(0|2|3|4|5|6|7|9)\d{7}$/,
  'g'
)
