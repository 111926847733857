import { AvatarUpload, SelectInput, TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

interface UserFormProps {
  form: FormikProps<{
    username: string;
    lastName: string;
    firstName: string;
    ghanaCardNumber: string;
    phoneNumber: string;
    emailAddress: string;
    gender: string;
    profileImageUrl: string;
    role: string;
  }>;
}

const UserForm: FC<UserFormProps> = ({ form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>User Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            <AvatarUpload id='profileImageUrl' label='Photo' {...form} />
          </div>

          <div className='col-start-1'>
            <TextInput
              id='username'
              label='AMC Username'
              type='text'
              placeholder='eg. 101314'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='ghanaCardNumber'
              label='Ghana Card'
              type='text'
              placeholder='Ghana Card'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='lastName'
              label='Last Name'
              type='text'
              placeholder='e.g. Mensah'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='firstName'
              label='First Name'
              type='text'
              placeholder='e.g. Enoch Nana Nyankah'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='phoneNumber'
              label='Phone Number'
              type='text'
              placeholder='e.g. 0550123292'
              required={true}
              {...form}
            />
          </div>

          <div>
            <TextInput
              id='emailAddress'
              label='Email Address'
              type='email'
              placeholder='e.g. nyankahmensah@gmail.com'
              required={true}
              {...form}
            />
          </div>
          <div>
            <SelectInput
              id='gender'
              label='Gender'
              options={[
                { label: "--- Select Gender ---", value: "" },
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              placeholder='e.g. nyankahmensah@gmail.com'
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForm;
