import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { useFormik } from "formik";
import { AssignBulkReplacementServiceOrdersForm, Modal } from "components";
import toast from "react-hot-toast";
import lodash from "lodash";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import * as yup from "yup";
import { currentDistrictVar } from "apollo/cache/auth";
import { IpAddressRegex, MacAddressRegex, MeterShipmentCustomerSerialNumberRegex, OnlyDigitsRegex } from "utils/regex";

const MAX_ORDERS = 2500

const ASSIGN_SERVICE_ORDER = gql`
  mutation AssignBulkReplacementServiceOrderMeterContractor(
    $assignments: [ReplacementServiceOrderAssignmentDataInput!]!
    $district: ID!
  ) {
    createReplacementServiceOrderAssignment(
      assignments: $assignments
      district: $district
    ) {
      _id
    }
  }
`;

const validationSchema = yup.object().shape({
  assignments: yup
    .array()
    .of(
      yup.object().shape({
        sn: yup.string().notRequired(),
        cmsRegionCode: yup.string().notRequired(),
        districtCode: yup.string().notRequired(),
        serviceOrderCode: yup
          .string()
          .required("Service Order Code is Required"),
        serviceOrderType: yup.string().notRequired(),
        servicePointCode: yup
          .string()
          .required("Service Point Code is Required"),
        customerName: yup.string().notRequired(),
        customerPhone: yup.string().notRequired(),
        emailAddress: yup.string().notRequired(),
        geoCode: yup.string().notRequired(),
        tariffClassCode: yup.string().required("Tariff Class is Required"),
        meterPhase: yup.string().required("Meter Phase is Required"),
        ghanaPostAddress: yup.string()
          .notRequired(),
        farmingOutContractor: yup
          .string()
          .required("Farming out contractor is Required"),
          presetAmount: yup.number().required("Preset Amount is Required"),
        batchId: yup.string().required("Batch ID is Required"),
        orgId: yup.string().required("Organization ID is Required"),
        cosemLogicalDeviceName: yup
          .string()
          .required("Cosem Logical device Name is Required")
          .matches(
            OnlyDigitsRegex, 
            "COSEM Logical Device Name must contain only digits"),
        mfgSerialNumber: yup.string()
          .required("MGF Serial Number is Required")
          .matches(
            OnlyDigitsRegex, 
            "Manufacturing Serial Number must contain only digits"),
        customerSerialNumber: yup
          .string()
          .required("Customer Serial Number is Required")
          .matches(
            MeterShipmentCustomerSerialNumberRegex, 
            "Kindly enter a valid Customer Serial Number"),
        manufacturer: yup.string().required("Manufacturer is Required"),
        modelType: yup.string().required("Model Type is Required"),
        ipAddress: yup.string()
          .required("IP Address is Required")
          .matches(
            IpAddressRegex, 
            "Kindly enter a valid IP address"),

        gprsModuleSerialNumber: yup
          .string()
          .required("GPRS Module Serial Number is Required")
          .matches(
            OnlyDigitsRegex, 
            "GPRS Module Serial Number must contain only digits"),
        firmwareType: yup.string().notRequired(),
        firmwareVersion: yup.string().required("Firmware Version is Required"),
        llsSecret: yup.string().notRequired(),
        hlsSecret: yup.string().notRequired(),
        authentication: yup.string().notRequired(),
        encryptionKey: yup.string().notRequired(),
        macAddress: yup.string()
          .notRequired()
          .matches(
            MacAddressRegex, 
            "Kindly enter a valid MAC address"),
        badgeId: yup.string()
          .required("Badge ID is Required")
          .matches(
            OnlyDigitsRegex, 
            "Badge ID must contain only digits"),
      })
    )
    .min(1, "Should contain at least a record")
    .max(MAX_ORDERS, `Should not exceed ${MAX_ORDERS} records`),
});

export default function AssignReplacementServiceOrderBulkContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const currentDistrict = useReactiveVar(currentDistrictVar);
  const [initiateReplacementServiceOrder] = useMutation(ASSIGN_SERVICE_ORDER);

  const form = useFormik<{
    assignments: {
      sn: string;
      cmsRegionCode: string;
      districtCode: string;
      serviceOrderCode: string;
      serviceOrderType: string;
      servicePointCode: string;
      customerName: string;
      customerPhone: string;
      emailAddress: string;
      geoCode: string;
      tariffClassCode: string;
      meterPhase: string;
      oldMeterNumber: string;
      faultType: string;
      ghanaPostAddress: string;
      farmingOutContractor: string;
      farmingOutAccount: string;
      presetAmount: number;
      batchId: string;
      orgId: string;
      cosemLogicalDeviceName: string;
      mfgSerialNumber: string;
      customerSerialNumber: string;
      manufacturer: string;
      modelType: string;
      ipAddress: string;
      gprsModuleSerialNumber: string;
      firmwareType: string;
      firmwareVersion: string;
      llsSecret: string;
      hlsSecret: string;
      authentication: string;
      encryptionKey: string;
      macAddress: string;
      badgeId: string;
    }[];
  }>({
    initialValues: {
      assignments: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values.assignments);
      await initiateReplacementServiceOrder({
        variables: {
          district: currentDistrict,
          assignments: lodash.map(values.assignments, (obj) =>
            lodash.omit(obj, ["oldMeterNumber", "faultType"])
          ).slice(0, MAX_ORDERS),
        },
      }).then(({ data }) => {
        if (data.createReplacementServiceOrderAssignment._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Assigned Successfully",
            })
          );
          refetch?.();
          form.resetForm()
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not assign Service Order",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hidePadding={true}
      hideActions={true}
      title="Upload Replacement Service Order (Bulk Upload)"
      size="7xl"
      description="Provide the details to upload installation service order"
    >
      {!form.isValid && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                <span>Errors Detected</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-red-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 overflow-y-scroll bg-red-100">
                <div>
                  {lodash.isArray(form.errors?.assignments) ? (
                    form?.errors?.assignments?.map((row, rowIndex) => (
                      <div key={rowIndex} className="text-red-700">
                        <span className="font-bold">Row: {rowIndex + 1}</span>
                        {lodash.isString(row) ? (
                          <span>{row}</span>
                        ) : (
                          <div className="flex flex-col">
                            {row?.sn ? <li>{row?.sn}</li> : null}
                            {row?.cmsRegionCode ? (
                              <li>{row?.cmsRegionCode}</li>
                            ) : null}
                            {row?.districtCode ? (
                              <li>{row?.districtCode}</li>
                            ) : null}
                            {row?.serviceOrderCode ? (
                              <li>{row?.serviceOrderCode}</li>
                            ) : null}
                            {row?.serviceOrderType ? (
                              <li>{row?.serviceOrderType}</li>
                            ) : null}
                            {row?.servicePointCode ? (
                              <li>{row?.servicePointCode}</li>
                            ) : null}
                            {row?.customerName ? (
                              <li>{row?.customerName}</li>
                            ) : null}
                            {row?.customerPhone ? (
                              <li>{row?.customerPhone}</li>
                            ) : null}
                            {row?.emailAddress ? (
                              <li>{row?.emailAddress}</li>
                            ) : null}
                            {row?.geoCode ? <li>{row?.geoCode}</li> : null}
                            {row?.tariffClassCode ? (
                              <li>{row?.tariffClassCode}</li>
                            ) : null}
                            {row?.meterPhase ? (
                              <li>{row?.meterPhase}</li>
                            ) : null}
                            {row?.ghanaPostAddress ? (
                              <li>{row?.ghanaPostAddress}</li>
                            ) : null}
                            {row?.farmingOutContractor ? (
                              <li>{row?.farmingOutContractor}</li>
                            ) : null}
                            {row?.farmingOutAccount ? (
                              <li>{row?.farmingOutAccount}</li>
                            ) : null}
                            {row?.presetAmount ? <li>{row?.presetAmount}</li> : null}
                            {row?.batchId ? <li>{row?.batchId}</li> : null}
                            {row?.orgId ? <li>{row?.orgId}</li> : null}
                            {row?.cosemLogicalDeviceName ? (
                              <li>{row?.cosemLogicalDeviceName}</li>
                            ) : null}
                            {row?.mfgSerialNumber ? (
                              <li>{row?.mfgSerialNumber}</li>
                            ) : null}
                            {row?.customerSerialNumber ? (
                              <li>{row?.customerSerialNumber}</li>
                            ) : null}
                            {row?.manufacturer ? (
                              <li>{row?.manufacturer}</li>
                            ) : null}
                            {row?.modelType ? <li>{row?.modelType}</li> : null}
                            {row?.gprsModuleSerialNumber ? (
                              <li>{row?.gprsModuleSerialNumber}</li>
                            ) : null}
                            {row?.firmwareType ? (
                              <li>{row?.firmwareType}</li>
                            ) : null}
                            {row?.firmwareVersion ? (
                              <li>{row?.firmwareVersion}</li>
                            ) : null}
                            {row?.llsSecret ? <li>{row?.llsSecret}</li> : null}
                            {row?.hlsSecret ? <li>{row?.hlsSecret}</li> : null}
                            {row?.authentication ? (
                              <li>{row?.authentication}</li>
                            ) : null}
                            {row?.encryptionKey ? (
                              <li>{row?.encryptionKey}</li>
                            ) : null}
                            {row?.macAddress ? (
                              <li>{row?.macAddress}</li>
                            ) : null}
                            {row?.badgeId ? <li>{row?.badgeId}</li> : null}
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <span>{form?.errors?.assignments}</span>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
      <AssignBulkReplacementServiceOrdersForm form={form} setShow={setOpen} maxOrders={MAX_ORDERS} />
    </Modal>
  );
}
